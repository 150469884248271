@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a{
  text-decoration: none;
}

a:-webkit-any-link {
  color: inherit;
}

a:visited {
  color: inherit;
}

/* SKELETON */
.skeleton {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0));
  -webkit-animation: shimmer 2s infinite;
  animation: shimmer 2s infinite;
  content: "";
}

@-webkit-keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

marquee {
  background: #000;
  color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 0;
  min-height: 28px;
}

marquee ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
}

marquee ul li {
  white-space: nowrap;
  list-style: none;
  margin: 0 20px;
  position: relative;
  font-size: 12px;
}

li:hover {
  text-decoration: underline;
  cursor: pointer;
}

li:after {
  content: "";
  border-right: 1px solid #fff;
  top: 0;
  right: -20px;
  height: 100%;
  position: absolute;
}

li:last-child:after {
  content: none;
}

@media screen and (max-width: 425px) {
  marquee {
    display: none;
  }
}

button {
  cursor: pointer;
}